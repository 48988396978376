var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Das Mannheimer Sperrmüll-Bingo!")]),_vm._m(0),_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"ma-grid"},_vm._l((_vm.qs),function(q){return _c('div',{key:q.name,class:{
          bg: !!q.name && !q.post,
          span2: q.span == 2,
          span3: q.span == 3,
          span4: q.span == 4,
          hide: q.hide,
        }},[(q.post)?_c('a',{staticClass:"img",style:({
            'background-image': 'url(/img/sperrmuell/' + q.post + '.jpg)',
          }),attrs:{"href":("https://www.instagram.com/p/" + (q.post) + "/"),"target":"_blank"}}):_vm._e(),(q.name)?_c('div',{staticClass:"txt"},[_vm._v(" "+_vm._s(q.name)+" ")]):_vm._e()])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Das ist das "),_c('i',[_vm._v("Mannheimer Sperrmüll-Bingo")]),_vm._v(". Da unsere Stadt so viel davon zu bieten hat, möchte ich hier den Müll aus so vielen Ecken wie möglich sammleln. "),_c('br'),_vm._v(" So funktionierts: Du siehst Spermüll an einem Quadrat, das hier noch leer ist? Schicke ein Bild davon auf Instagram an "),_c('a',{attrs:{"href":"https://www.instagram.com/sperrmuell.ma/"}},[_vm._v("@sperrmuell.ma")]),_vm._v(". "),_c('br'),_c('b',[_vm._v("Hilf mit, das Bild zu vervollständigen.")])])}]

export { render, staticRenderFns }